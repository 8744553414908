@import "typography.sass";
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif; }
a {
  text-decoration: none;
  color: #ffffff; }
a:hover {
  color: #FFC326; }
li {
  list-style-type: none; }

h2 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 3px; }
h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px; }
