@media screen and (max-width: 800px) {
  #header {
    .logo_after {
      display: flex;
      justify-content: baseline;
      width: 100%;
      .mobile_panel {
        display: flex;
        width: 90%;
        height: 80px;
        align-items: center;
        justify-content: space-between;
        .m_logo {
          display: flex;
          margin-left: 20px;
          color: #ffffff;
          align-content: center;
          align-items: center;
          img {
            width: auto;
            height: 40px; }
          .m_text_logo {
            color: #ff0000;
            margin-left: 24px;
            display: flex;
            font-weight: bold;
            font-size: 18px;
            flex-direction: column;
            align-items: center;
            span {
              font-weight: 200;
              font-size: 16px;
              color: #ffffff; } } }
        .mobile_button {
          z-index: 20;
          width: 30px;
          height: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          #middle {
            height: 1px;
            width: 30px;
            background: rgb(254, 255, 251); }
          #bottom {
            height: 1px;
            width: 30px;
            background: rgb(254, 255, 251); }
          #top {
            height: 1px;
            width: 30px;
            background: rgb(255, 255, 255); } } } }
    .nav {
      display: none; } }
  .mobile_tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    width: 100%;
    .mobile_nav {
      display: flex;
      line-height: 24px;
      margin-left: 30px;
      font-size: 18px;
      flex-direction: column;
      width: 90%;
      padding: 10px;
      li {
        padding-top: 10px;
        a:hover {
          color: #FFC326; } } } }
  .wrap_banner {
    margin-top: 0;
    flex-direction: column;
    height: auto;
    align-items: center;
    .banner {
      width: 100%;
      height: 60px; }
    .banner:nth-child(2) {
      -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%); } }
  .wrap_content {
    .compani {
      flex-direction: column;
      img {
        width: 90%;
        height: auto; }
      .col {
        width: 100%;
        font-size: 16px; }
      p {
        text-align: justify; } } }
  .our_services {
    width: 100%;
    .wr_service {
      display: flex;
      flex-direction: column;
      width: 100%;
      .service {
        flex-direction: column;
        width: 100%;
        li {
          width: 90%;
          margin-top: 10px; } } } }
  .info {
    .info_row {
      flex-direction: column;
      align-items: center;
      .contact {
        width: 98%;
        .contact_text {
          width: 100%; }
        .tel {
          width: 100%;
          p {} } }

      iframe {
        width: 95%;
        margin-top: 40px; } } }
  .price {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .table-wrap {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 98%;
      border: solid #c4c2c2;
      background: #545353;
      .t-col {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid #c4c2c2;
        border-width: 3px;
        .t-row {
          padding: 10px;
          display: flex;
          width: 60%;
          color: #ffffff;
          font-weight: bolder;
          text-transform: uppercase;
          text-align: left; }
        .t-row:nth-child(1) {
          padding-left: 20px;
          font-size: 16px;
          letter-spacing: 2px; }
        .t-row:nth-child(2) {
          padding-right: 20px;
          font-size: 16px;
          border: none;
          align-items: center;
          justify-content: center;
          width: 20%;
          font-weight: bold;
          letter-spacing: 2px;
          color: #ffffff; } } } } }
