@import "common/base.sass";
@import "common/mediaqueries.sass";
.row {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%; }
.col {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center; }
.slick-next {
  color: #161616; }
#header {
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  color: #FFFFFF;
  background: #000000;
  width: 100%;
  .nav {
    display: flex;
    width: 100%;
    height: 98px;
    font-family: 'Roboto', sans-serif;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    .logo_wrap {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-transform: uppercase;
      img {
        margin-right: 10px; }
      .text_logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 45px;
        letter-spacing: 2px;
        span {
          color: #ED4543;
          text-align: center;
          font-weight: bold; }
        p {
          font-size: 20px;
          font-weight: bolder;
          margin-right: 95px;
          line-height: 20px;
          letter-spacing: 2px; } } } }
  .logo_after {
    display: none;
    text-transform: uppercase; }
  .mobile_tab {
    display: none;
    background: #000000;
    margin-top: 80px;
    color: rgb(255, 255, 255);
    z-index: 2;
    position: absolute;
    width: 100%;
    opacity: 0.9; } }
.wrap_banner {
  display: flex;
  justify-content: space-around;
  height: 98px;
  width: 100%;
  background: #545353;
  .banner {
    display: flex;
    align-items: center;
    width: 33.33333333%;
    height: 98px;
    justify-content: center;
    font-size: 22px;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: 500;
    color: #ffffff; }
  .banner:nth-child(2) {
    font-weight: bold;
    font-size: 30px;
    background: #ED4543;
    -webkit-clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
    clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%); } }
.wrap_content {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 90%;
  text-align: center;
  h2 {
    margin-bottom: 20px; }
  .compani {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    align-self: center;
    .img {
      img {
        height: auto; } }
    .col {
      width: 50%;
      align-self: stretch;
      p {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        margin-top: 0;
        line-height: 24px;
        text-align: justify;
        a {
          font-weight: bold;
          color: #010101; }
        a:hover {
          color: #FFC326; } } } } }
.our_services {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  h2 {
    margin-top: 30px;
    width: 90%; }

  .wr_service {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    align-content: center;
    margin-top: 30px;
    color: #ffffff;
    font-size: 18px;
    width: 100%;
    .service {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      width: 100%;
      li {
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        width: 18%;
        height: 250px;
        background: #545353;
        img {
          margin-top: 20px;
          width: 150px;
          height: auto; } }
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: none;
        text-align: center;
        font-size: 20px;
        padding-left: 5px;
        padding-right: 5px;
        background: #ED4543;
        height: 90px;
        width: 100%; }
      .sum {
        font-size: 18px;
        background: #ED4543;
        width: 100%; } } } }
.price {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .table-wrap {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    border: solid #c4c2c2;
    background: #545353;
    .t-col {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid #c4c2c2;
      border-width: 3px;
      .t-row {
        padding: 10px;
        display: flex;
        width: 60%;
        color: #ffffff;
        text-transform: none;
        text-align: left; }
      .t-row:nth-child(1) {
        padding-left: 20px;
        font-size: 20px; }
      .t-row:nth-child(2) {
        padding-right: 20px;
        font-size: 20px;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        width: 20%;
        text-transform: lowercase;
        border-left: solid #c4c2c2;
        color: #ffffff; } } } }
.our_work {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 90%;
  align-items: center;
  h2 {
    width: 100%;
    background: #ffffff;
    text-align: center;
    font-weight: bolder; }
  .slider {
    width: 90%;
    height: auto;
    .sl {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;
      img {
        height: 200px;
        width: auto; } } } }
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 40px;
  .info_row {
    margin-top: 20px;
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-bottom: 20px;
    .contact {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      h3:nth-last-child(2) {
        margin-top: 30px; }
      .contact_text {
        display: flex;
        flex-direction: column;
        width: 90%;
        justify-content: center;
        align-content: center;
        p:nth-of-type(2) {
          margin-top: 10px; }
        span {
          font-weight: bold;
          color: #000000; }
        a {
          color: #000000; }
        a:hover {
          color: #ffce1f; } }
      .tel {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-top: 10px;
        p {}
        a {
          color: #ED4543; }
        a:nth-child(1) {
          margin-top: 20px; }
        a:hover {
          color: #f18b13; }
        span {
          font-weight: bold;
          color: #000000; } } }
    iframe {
      width: 50%; } } }
@import "components/footer.sass";
@import "common/media.sass";
